import { SEARCH_SUCCESS, SET_PAGE_SIZE, SET_VIEW_TYPE } from "../../constants/ActionTypes";
import axios from "../../services/api";
import { FETCH_START, FETCH_SUCCESS } from "../../constants/ActionTypes";
import { getCookie, setCookie } from "../../utils/helpers";
import { buildElasticSearchQuery } from "./Filters";
import { fetchError } from "./Common";
import { setFilterTermAggregation } from "./Checkboxes";
import { MAP_VIEW } from "../../../GlobalSearch/constants/appConstants";

/**
 * Get our Django csrf token
 */
const csrftoken = getCookie("csrftoken");

function logMatchedFields(explainObj) {
    function parseExplanation(details) {
        // Store matched fields with term and score information
        const matches = [];

        // Recursive function to extract field, term, and score data
        function extractMatches(detail) {
            if (detail.description.includes("weight")) {
                // Example format: weight(description_fr:mrc in 408)
                const match = /weight\(([^:]+):([^ ]+)/.exec(detail.description);
                if (match) {
                    const field = match[1];
                    const term = match[2];
                    const score = detail.value;
                    matches.push({ field, term, score });
                }
            }
            if (detail.details && detail.details.length > 0) {
                detail.details.forEach(extractMatches);
            }
        }

        // Start parsing from the root details
        details.forEach(extractMatches);
        return matches;
    }

    // Parse and get matches
    const matches = parseExplanation(explainObj.details);

    // Log the matches in console
    matches.forEach(({ field, term, score }) => {
        console.log(`Matched Field: ${ field }, Term: "${ term }", Score: ${ score }`);
    });
}

/**
 * Start search
 *
 * @param {*} actionType
 * @param {*} fieldDocument
 * @return {*}
 */
export const startSearch = (callback = () => {
}) => {
    return (dispatch, getState) => {
        const lang = getState().common.currentLanguage || "en";
        dispatch(buildElasticSearchQuery());
        dispatch({ type: FETCH_START });
        axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
        axios
            .post(`/${ lang }/api/search/`, getState().filters.payload)
            .then(({ data }) => {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({
                    type: SEARCH_SUCCESS, payload: data,
                });
                if (data?.count > 0) {
                    data.results.forEach((result) => {
                        try {
                            console.log("vvvvvvvvvvvvvvvvvvvvvvvvvvvvv");
                            console.log(result.result.name_fr, result.result._explain.value);
                            if (result.result._explain) {
                                logMatchedFields(result.result._explain);
                            }
                            console.log(result.result._explain);
                            console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^");
                        } catch (e) {
                            console.log(e);
                        }

                    });
                }
                callback();
                dispatch(setFilterTermAggregation());
            })
            .catch((error) => {
                dispatch(fetchError({ message: error.message, status: error.response.status }));
            });
    };
};

export const setResultViewtype = (viewType) => {
    return (dispatch) => {
        dispatch({
            type: SET_PAGE_SIZE, payload: viewType === MAP_VIEW ? 9999 : 10,
        });
        dispatch({
            type: SET_VIEW_TYPE, payload: viewType,
        });
        dispatch(buildElasticSearchQuery());
        dispatch(startSearch());
    };
};
